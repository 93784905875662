import Layout from "../../components/layout"
import Hero from "../../components/hero"
import React, { Component } from "react"
import Reviews from "../../components/reviews"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../../components/seo"
import OptInForm from '../../components/optInForm'
import GoogleReviewCarousel from "../../components/google-review-carousel"
import * as ServiceAreasStyles from "../../styles/service-areas.module.scss"

const RoscoeVillagePage = () => {
    const data = useStaticQuery(graphql`
    query RoscoeVillagePageQuery {
      roscoeVillageImage: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/roscoe-village-chicago" }
      ) {
        secure_url
      }
      cabinetPaintingImage: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/cabinet-painting-pivot-painters" }
      ) {
        secure_url
      }
      interiorPaintingImage: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/interior-painting-pivot-painters" }
      ) {
        secure_url
      }
    }
  `)
    return (
        <Layout>
            <SEO title="Roscoe Village Painters | Painting Contractors in Chicago Roscoe Village" description="Pivot Painters is one best painting companies servicing Roscoe Village Chicago, IL. Dedicated to providing quality painting and customer service to our customers." />
            <Hero name="Roscoe Village Painters" src={data.roscoeVillageImage.secure_url} />
            <div className={ServiceAreasStyles.serviceAreaContainer}>
                <h2>#1 Choice for Painting in Roscoe Village, IL</h2>
                <div className={ServiceAreasStyles.serviceAreaIntroSubContainer}>
                    <p>
                        Pivot Painters - The top painting service in Roscoe Village, Chicago is Your Trusted House Painters, we offer competitive, expert, and competitive painting services. This distinguishes us from other painters. <br /><br />Spend less time and money while giving your home, place of business, or any other space a fresh new look! We use the most up-to-date painting methods and premium paints, and we treat every residential and business area as though it were a work of art.<br /><br /> Additionally, we offer advice on the latest color trends, the best paint to use, and other painting tips that will work with your house. We are the reliable option for interior painting in Roscoe Village, Chicago!
                    </p>
                    {/*<a href="tel:3128549180">‪(312) 854-9180‬</a>*/}
                </div>
                <h2>Professional Painting Services in Roscoe Village</h2>
                <p className={ServiceAreasStyles.serviceAreaServicesSubHeader}>If you need a professional painter near you, turn to Pivot Painters.<br /> Proudly serving the zip code 60618 and 60657 in Roscoe Village, IL</p>
                <div className={ServiceAreasStyles.serviceAreaServicesSubContainer}>
                    <a href="/interior-painting-chicago">
                        <img src={data.interiorPaintingImage.secure_url} alt="" />
                        <p className={ServiceAreasStyles.serviceAreaServicesTitle}>Interior Painting</p>
                        <p className={ServiceAreasStyles.serviceAreaServicesDescription}>Pivot Painters provides quality interior painting services, creating a seamless experience for all of our clients.</p>
                    </a>
                    <a href="/cabinet-refinishing-chicago">
                        <img src={data.cabinetPaintingImage.secure_url} alt="" />
                        <p className={ServiceAreasStyles.serviceAreaServicesTitle}>Cabinet Painting</p>
                        <p className={ServiceAreasStyles.serviceAreaServicesDescription}>Pivot Painters is a leader in cabient refinishing, we use high quality wood coatings imported from Italy for all projects.</p>
                    </a>
                </div>
                <h2>Why Choose Pivot Painters?</h2>
                <div className={ServiceAreasStyles.serviceAreaWhyUsContainer}>
                    <ul>
                        <li><strong>Reliable - </strong> No more chasing Contractors. We understand how frustrating it can be working with unprofessional painters, and that's why when when we say we are going to do something it gets done. Period.</li><br></br>
                        <li><strong>Insured - </strong> Having painters into your home and business can be nerve racking but you don't have to worry about that with Pivot Painters. We are fully insured for your peace of mind.</li><br></br>
                        <li><strong>Quality - </strong> Not only do we use leading painting techniques but we also use the highest quality paints and tools to ensure your project comes out perfect.</li><br></br>
                        <li><strong>Communication - </strong> Using cutting edge tech and good ol' empathy we create a seamless experience for our clients to get their projects completed the way they envisioned.</li><br></br>
                    </ul>
                </div>
                <h2>What Past Customers in Roscoe Village Are Saying</h2>
                <GoogleReviewCarousel />
                <OptInForm></OptInForm>
                <div className={ServiceAreasStyles.resources}>
                    <p>Resources In Roscoe Village, IL</p>
                    <a href="https://www.roscoevillage.org/">Roscoe Village Neighbors</a>
                    <a href="https://www.neighborhoods.com/neighborhood-guides/il/chicago/north-side/roscoe-village">Roscoe Village - Chicago Neighborhood Guide</a>
                </div>
            </div>
        </Layout>
    )
}

export default RoscoeVillagePage
